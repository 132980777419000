import {CustomLoader} from "../../components/Spinner/CustomLoader";
import {Navigate, Route, Routes} from "react-router-dom";
import React, {lazy, Suspense, useContext} from "react";
import {DashboardLayout} from "../../components/Layout/DashboardLayout";
import {ChartBar as ChartBarIcon} from "../../icons/chart-bar";
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import FlagIcon from '@mui/icons-material/Flag';
import TuneIcon from '@mui/icons-material/Tune';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ApprovalIcon from '@mui/icons-material/Approval';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SummarizeIcon from '@mui/icons-material/Summarize';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {AppStateContext} from "../../App";

const SiteCreate = lazy(() => import('./Organizations/Locations/Sites/SiteCreate'));
const LocationCreate = lazy(() => import('./Organizations/Locations/Locations.Create'));
const AdminDashboard = lazy(() => import('./Dashboard/Admin.Dashboard'));
const OrganizationsRoutes = lazy(() => import('./Organizations/Organizations.Routes'));
const ContractorsRoutes = lazy(() => import('./Contractors/Contractors.Routes'));
const AdminReports = lazy(() => import('./Reports/Admin.Reports'));
const InventoriesRoutes = lazy(() => import('./Inventories/Inventories.Routes'));
const ChecklistsRoute = lazy(() => import('./Checklists/Checklists.Route'));
const StatesList = lazy(() => import('./States/States.Route'));
const EquipmentsRoute = lazy(() => import('./Equipments/Equipments.Route'));
const BillingRoute = lazy(() => import('./Billing/Billing.Route'));
const UsersRoute = lazy(() => import('./Users/Users.Route'));
const EngineersRoute = lazy(() => import('./Engineers/Engineers.Route'));
const AuditorsRoute = lazy(() => import('./Auditor/Auditors.Route'));
const AdminsRoute = lazy(() => import('./Admins/Admins.Route'));
const CertificateTemplateRoute = lazy(() => import('./CertificateLayout/CertificateLayout.Route'));
const StampsRoute = lazy(() => import('./Stamps/Stamps.Route'));
const QuotationTemplateRoute = lazy(() => import('./QuotationTemplate/QuotationTemplate.Route'));
const ContactUs = lazy(() => import('../Common/ContactUS/ContactUS'));
const AuditNumberRoute = lazy(() => import('./AuditNumbers/AuditNumber.Route'));
const LocationMaster = lazy(() => import('./LocationMaster/LocationMaster.Route'));
const SiteMaster = lazy(() => import('./SitesMaster/SiteMaster.Route'));
const Backup = lazy(() => import('./Backup/Backup'));
const RegistrationRequestsRoutes = lazy(() => import('./RegistrationRequests/RegistrationRequests.Routes'));
const TPIRoutes = lazy(() => import('./TPI/TPI.Routes'));
const LoginRequestsRoutes = lazy(() => import('./LoginRequests/LoginRequests.Routes'));
const ManualNotificationsRoutes = lazy(() => import('./ManualNotifications/ManualNotifications.Routes'));
const SearchPage = lazy(() => import('../Common/Search/Search.Page'));
export default function AdminRoutes() {

    const {quick_access, loggedInUser} = useContext(AppStateContext);
    const items = [
        {
            href: '/admin/dashboard',
            icon: (<ChartBarIcon fontSize="small"/>),
            title: 'Dashboard'
        },

        {
            href: '/admin/search',
            icon: (<SearchIcon fontSize="small"/>),
            title: 'Search by ID or QR'
        },
        {
            href: '/admin/organizations',
            icon: (<BusinessIcon fontSize="small"/>),
            title: 'Organizations'
        },
        {
            href: '/admin/contractors',
            icon: (<BusinessIcon fontSize="small"/>),
            title: 'Contractors'
        },
        {
            href: '/admin/inventories',
            icon: (<PrecisionManufacturingIcon fontSize="small"/>),
            title: 'Inventories'
        },
        {
            href: '/admin/billing',
            icon: (<PointOfSaleIcon fontSize="small"/>),
            title: 'Billing',
            sub_menus: [
                ...(loggedInUser && loggedInUser.admin && (loggedInUser.admin.billing_admin || loggedInUser.admin.account_admin))?[{
                    href: '/admin/billing/quotations',
                    icon: (<ReceiptIcon fontSize="small"/>),
                    title: 'Quotations'
                }]:[],
                ...(loggedInUser && loggedInUser.admin && (loggedInUser.admin.billing_admin || loggedInUser.admin.account_admin))?[{
                    href: '/admin/billing/work-orders',
                    icon: (<ConfirmationNumberIcon fontSize="small"/>),
                    title: 'Work Orders'
                }]:[],
                ...(loggedInUser && loggedInUser.admin && (loggedInUser.admin.billing_admin || loggedInUser.admin.account_admin))?[{
                    href: '/admin/billing/invoices',
                    icon: (<LocalAtmIcon fontSize="small"/>),
                    title: 'Invoices'
                }]:[],
                ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.account_admin)?[{
                    href: '/admin/billing/payment-logs',
                    icon: (<CurrencyRupeeIcon fontSize="small"/>),
                    title: 'Payment Logger'
                }]:[],
                ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin)?[{
                    href: '/admin/billing/reports',
                    icon: (<SummarizeIcon fontSize="small"/>),
                    title: 'Reports'
                }]:[],
            ]
        },

        ...((loggedInUser && loggedInUser.admin && loggedInUser.admin.can_see_tpi_requests) || (loggedInUser && loggedInUser.admin && loggedInUser.admin.can_edit_tpi_requests)) ?
            [{

                icon: (<RecentActorsIcon fontSize="small"/>),
                title: 'TPI',
                sub_menus: [
                    ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_edit_tpi_requests)?[{
                        href: '/admin/tpi/call-generate',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'Call Generate'
                    }]:[],
                    ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_see_tpi_requests)?[{
                        href: '/admin/tpi/new',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI Client Requests'
                    },]:[],
                    ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_see_tpi_requests)?[ {
                        href: '/admin/tpi/tpi-active',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI in Progress'
                    },]:[],
                    ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_see_tpi_requests)?[
                        {
                            href: '/admin/tpi/tpi-history',
                            icon: (<DesignServicesIcon fontSize="small"/>),
                            title: 'TPI History'
                        },]:[],
                    ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_see_tpi_requests)?[

                        {
                            href: '/admin/tpi/tpi-reports',
                            icon: (<DesignServicesIcon fontSize="small"/>),
                            title: 'Report'
                        },]:[],


                ]
            },] : [],


        ...((loggedInUser && loggedInUser.admin && loggedInUser.admin.can_approve_login_requests) || (loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users)) ?
            [
                {

                    icon: (<RecentActorsIcon fontSize="small"/>),
                    title: 'Requests',
                    sub_menus: [
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_approve_login_requests) ?
                            [{
                                href: '/admin/login-requests',
                                icon: (<LoginIcon fontSize="small"/>),
                                title: 'Login Requests'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users) ?
                            [{
                                href: '/admin/registration-requests',
                                icon: (<HowToRegIcon fontSize="small"/>),
                                title: 'Registration Requests'
                            },] : [],
                    ]

                },
            ] : [],


        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_send_manual_notifications) ?
            [{
                href: '/admin/manual-notifications',
                icon: (<EditNotificationsIcon fontSize="small"/>),
                title: 'Manual Notifications'
            },] : [],


        ...((loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users) || (loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin)) ?
            [
                {

                    icon: (<RecentActorsIcon fontSize="small"/>),
                    title: 'Users',
                    sub_menus: [
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users) ?
                            [{
                                href: '/admin/users',
                                icon: (<RecentActorsIcon fontSize="small"/>),
                                title: 'Users'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users) ?
                            [{
                                href: '/admin/engineers',
                                icon: (<RecentActorsIcon fontSize="small"/>),
                                title: 'Engineers'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.can_manage_users) ?
                            [{
                                href: '/admin/auditors',
                                icon: (<RecentActorsIcon fontSize="small"/>),
                                title: 'Auditors'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [{
                                href: '/admin/admins',
                                icon: (<RecentActorsIcon fontSize="small"/>),
                                title: 'Admins'
                            }] : [],
                    ]

                },
            ] : [],
        ...((loggedInUser && loggedInUser.admin && loggedInUser.admin.can_edit_data)) ?
            [
                {

                    icon: (<TuneIcon fontSize="small"/>),
                    title: 'Masters',
                    sub_menus: [

                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [  {
                                href: '/admin/checklists',
                                icon: (<LibraryAddCheckIcon fontSize="small"/>),
                                title: 'Checklists'
                            },] : [],

                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [ {
                                href: '/admin/certificate-templates',
                                icon: (<ReceiptLongIcon fontSize="small"/>),
                                title: 'Certificate Templates'
                            },] : [],

                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [ {
                                href: '/admin/stamps',
                                icon: (<ApprovalIcon fontSize="small"/>),
                                title: 'Stamps'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [  {
                                href: '/admin/equipments',
                                icon: (<PrecisionManufacturingIcon fontSize="small"/>),
                                title: 'Equipments'
                            },] : [],

                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [   {
                                href: '/admin/services',
                                icon: (<EngineeringIcon fontSize="small"/>),
                                title: 'Services'
                            },] : [],
                        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin) ?
                            [    {
                                href: '/admin/quotation-templates',
                                icon: (<ReceiptIcon fontSize="small"/>),
                                title: 'Quotation Templates'
                            },] : [],



                        {
                            href: '/admin/location-master',
                            icon: (<FmdGoodIcon fontSize="small"/>),
                            title: 'Location Master'
                        },
                        {
                            href: '/admin/site-master',
                            icon: (<ShareLocationIcon fontSize="small"/>),
                            title: 'Site Master'
                        },
                        {
                            href: '/admin/states',
                            icon: (<FlagIcon fontSize="small"/>),
                            title: 'States'
                        },
                    ]

                },
            ] : [],

        {
            href: '/admin/reports',
            icon: (<AssessmentIcon fontSize="small"/>),
            title: 'Reports'
        },
        {
            href: '/admin/audit-numbers',
            icon: (<CardMembershipIcon fontSize="small"/>),
            title: 'Audit Numbers'
        },
        ...(loggedInUser && loggedInUser.admin && loggedInUser.admin.is_super_admin)?[
            {
                href: '/admin/backup',
                icon: (<AssessmentIcon fontSize="small"/>),
                title: 'Backup'
            },
        ]:[],

        {
            href: '/admin/contact-us',
            icon: (<AlternateEmailIcon fontSize="small"/>),
            title: 'Contact us'
        },

    ];
    return <DashboardLayout menu={items}>
        <Suspense fallback={<CustomLoader/>}>
            <Routes>
                <Route path="/dashboard" element={<AdminDashboard/>}/>
                <Route path="/search" element={<SearchPage/>}/>
                <Route path="/organizations/*" element={<OrganizationsRoutes/>}/>
                <Route path="/contractors/*" element={<ContractorsRoutes/>}/>
                <Route path="/location/create" element={<LocationCreate/>}/>
                <Route path="/site/create" element={<SiteCreate/>}/>
                <Route path="/inventories/*" element={<InventoriesRoutes/>}/>
                <Route path="/checklists/*" element={<ChecklistsRoute/>}/>
                <Route path="/equipments/*" element={<EquipmentsRoute/>}/>
                <Route path="/billing/*" element={<BillingRoute/>}/>
                <Route path="/states/*" element={<StatesList/>}/>
                <Route path="/registration-requests/*" element={<RegistrationRequestsRoutes/>}/>
                <Route path="/tpi/*" element={<TPIRoutes/>}/>
                <Route path="/login-requests/*" element={<LoginRequestsRoutes/>}/>
                <Route path="/manual-notifications/*" element={<ManualNotificationsRoutes/>}/>
                <Route path="/users/*" element={<UsersRoute/>}/>
                <Route path="/engineers/*" element={<EngineersRoute/>}/>
                <Route path="/auditors/*" element={<AuditorsRoute/>}/>
                <Route path="/admins/*" element={<AdminsRoute/>}/>
                <Route path="/certificate-templates/*" element={<CertificateTemplateRoute/>}/>
                <Route path="/stamps/*" element={<StampsRoute/>}/>
                <Route path="/quotation-templates/*" element={<QuotationTemplateRoute/>}/>
                <Route path="/reports/*" element={<AdminReports/>}/>
                <Route path="/contact-us/*" element={<ContactUs/>}/>
                <Route path="/audit-numbers/*" element={<AuditNumberRoute/>}/>
                <Route path="/location-master/*" element={<LocationMaster/>}/>
                <Route path="/site-master/*" element={<SiteMaster/>}/>
                <Route path="/backup/*" element={<Backup/>}/>
                <Route path="*" element={<Navigate to="dashboard"/>}/>
            </Routes>
        </Suspense>
    </DashboardLayout>
}
