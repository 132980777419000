import axios, {CancelTokenSource} from 'axios';
import {iApiBasicResponse, iCsrfToken, iCsrfTokenResponse, iFormData, iMultilevelStringOrNumber} from "../types/types";

import fileDownload from 'js-file-download'
import {AlertColor} from "@mui/material/Alert/Alert";

export class ObjectFlatter {
    private readonly flattened_data: Array<iFormData>;

    constructor(params: iMultilevelStringOrNumber) {
        this.flattened_data = [];
        this.process_params(params, "");
    }

    process_params = (params: iMultilevelStringOrNumber, suffix: string) => {
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const key_with_suffix = suffix ? (suffix + '[' + key + ']') : key;
                const value = params[key];
                if (typeof value === 'string') {
                    this.flattened_data.push({key: key_with_suffix, value: value});
                } else if (typeof value === 'number') {
                    this.flattened_data.push({key: key_with_suffix, value: value + ""});
                } else if (typeof value === 'boolean') {
                    this.flattened_data.push({key: key_with_suffix, value: (value ? "true" : "false")});
                } else if (typeof value === 'undefined') {
                    // Do nothing.
                } else {
                    // Check for arrays.
                    let value_object: iMultilevelStringOrNumber;
                    if (Array.isArray(value)) {
                        value_object = {};
                        value.forEach((val: any, index: number) => value_object[index + ''] = val);
                    } else {
                        // @ts-ignore
                        value_object = value;
                    }
                    this.process_params(value_object, key_with_suffix);
                }
            }
        }
    };

    get_form_data = (): Array<iFormData> => {
        return this.flattened_data
    };
}

export default class BaseAPIs {
    static csrfToken: iCsrfToken = {
        token: "dummy_expired_token",
        expiry: new Date(0)
    };

    static hasError = (response: iApiBasicResponse, showSuccessSnackbarMessage?: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void) => {

        if (!response || response.statusCode >= 400) {
            response &&
            response.message &&
            showSuccessSnackbarMessage &&
            showSuccessSnackbarMessage({message: response.message, severity: "error", show: true})
        } else {
            response &&
            response.message &&
            showSuccessSnackbarMessage &&
            showSuccessSnackbarMessage({message: response.message, severity: "success", show: true})
        }
        if (!response || response.statusCode >= 400) return true;
        if (!response || response.validation_errors) return true;
    };

    static getError = (response: iApiBasicResponse): string => {
        if(!response)return "";
        if (response.message && typeof response.message === "string") return response.message;
        if (!response.validation_errors) return BaseAPIs.getErrorFromStatusCode(response);
        if (response.validation_errors.csrf) return BaseAPIs.getErrorString(response.validation_errors.csrf);
        if (response.validation_errors.network) return BaseAPIs.getErrorString(response.validation_errors.network);

        if (response.validation_errors.email) return BaseAPIs.getErrorString(response.validation_errors.email);
        if (response.validation_errors.password) return BaseAPIs.getErrorString(response.validation_errors.password);

        if (response.validation_errors.name) return BaseAPIs.getErrorString(response.validation_errors.name);
        return "Unknown error!";
    };

    static getErrorFromStatusCode = (response: iApiBasicResponse): string => {
        switch (response.statusCode) {
            case 400:
                return "Request error: Bad Request";
            case 401:
                return "Request error: Unauthorized";
            case 402:
                return "Request error: Payment Required";
            case 403:
                return "Request error: Forbidden";
            case 404:
                return "Request error: Not Found";
            case 405:
                return "Request error: Method Not Allowed";
            case 500:
                return "Server error: Internal error";
            case 501:
                return "Server error: Not Implemented";
            case 502:
                return "Server error: Bad Gateway";
            case 503:
                return "Server error: Service Unavailable";
            case 504:
                return "Server error: Gateway Timeout";
            default:
                return "Unknown error";
        }
    };

    private static getErrorString(error: string | Array<string>) {
        if (typeof error === "string") return error;
        else return error.join(" ");
    }

    post = async (url: string, fd?: any): Promise<any> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken(true);
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        let data: any = {};
        if (fd) {
            data = {...fd}
            data["_token"] = csrfToken;
        } else {
            data["_token"] = csrfToken;
        }
        return axios
            .post(this.getApiBaseURL() + url, data, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: BaseAPIs.getError(error.response), ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: BaseAPIs.getError(error.response)};
                }
            })
    };

    get = async (url: string, params?: any, abortController?: AbortController): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                signal: abortController && abortController.signal
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: BaseAPIs.getError(error.response), ...error.response.data, statusCode: error.response.status
                    }
                }
                if (error && error.response && error.response.status) {
                    if (error.response.status === 404) {
                        return {
                            message: BaseAPIs.getError(error.response), statusCode: error.response.status
                        }
                    }
                    return {
                        message: BaseAPIs.getError(error.response), statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: BaseAPIs.getError(error.response)};
                }
            })
    };
    download = async (url: string, download_name: string, params?: any, onDownloadProgress?: any): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                cancelToken: params && params.cancel_token_source && params.cancel_token_source.token,
                responseType: "blob",
                onDownloadProgress: onDownloadProgress
            })
            .then((res: any): any => {
                fileDownload(res.data, download_name);
            })
            .catch((): any => {

            })
    };

    post_with_multipart = async (url: string, fd: any, onUploadProgress?: any): Promise<any> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken(true);
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        fd.set("_token", csrfToken);

        return axios
            .post(this.getApiBaseURL() + url, fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
                onUploadProgress: onUploadProgress
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };

    getApiBaseURL = () => {
        const DEBUG = process.env.NODE_ENV !== 'production';
        return DEBUG ? process.env.REACT_APP_DEV_SERVER : (window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
    };
    getGetUrl = (url:string,params:any) => {

        Object.keys(params).forEach((key) => {
            if (params[key] === undefined || params[key] === '') {
                delete params[key];
            }
        });
        const u = new URLSearchParams(params).toString();


        return this.getApiBaseURL()+url+"?"+u;
     };
    getApiHost = () => {
        const DEBUG = process.env.NODE_ENV !== 'production';
        return DEBUG ?  "uniquo.dev4.volobot.com" : window.location.hostname;
    };

    getCsrfToken = async (refresh_token: boolean = false, source?: CancelTokenSource): Promise<string> => {
        return this.fetchCsrfToken(source).then((r: any) => {
            if (BaseAPIs.hasError(r) || !r.token) {
                return "";
            } else {
                return r.token;
            }
        })
    };

    fetchCsrfToken = (source?: CancelTokenSource): Promise<iCsrfTokenResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v1/csrf-token", {
                withCredentials: true,
                cancelToken: source && source.token
            },)
            .then((res): iCsrfTokenResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
            });
    };
}
